import { get, post } from '@/lib/util'
import { deliveryInfoSlice } from '@/lib/redux'

export const getDeliveryInfoAsync =
  (date) =>
    async (dispatch, getState) => {
      get(`/order/getDeliveryInfo?date=${date}`, (res) => {
        if (res.code) return;
        let { orders } = res;
        dispatch(deliveryInfoSlice.actions.setList({ orders, date }));
      });
    }

export const setDeliveryStateAsync =
  (item, delivered) =>
    async (dispatch, getState) => {
      let data = await post(`/order/delivered?phone=${item.phone}&date=${item.date}&delivered=${delivered}`)
      dispatch(getDeliveryInfoAsync(item.date))
    }





