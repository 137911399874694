import { get, post } from '@/lib/util'
import { buildingInfoSlice } from '@/lib/redux'

export const getBuildingInfoAsync =
  () =>
    async (dispatch, getState) => {
      let data = await get(`/building/getBuildingInfo`);
      if (!data) return;
      dispatch(buildingInfoSlice.actions.setList(data))
    }




