import { get, post } from '@/lib/util'
import { userSlice } from '@/lib/redux'

export const getUserListAsync =
  () =>
    async (dispatch, getState) => {
      let data = await get('/user/list');
      if (!data)  return;
      dispatch(userSlice.actions.setList(data))
    }
