import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  list: {},
}

export const buildingInfoSlice = createSlice({
  name: 'buildingInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
  },
})

