
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
  },
})

