import { TabBar } from 'antd-mobile'
import { AppOutline, UserOutline } from 'antd-mobile-icons'
import { useNavigate, useResolvedPath } from "react-router-dom";


const tabs = [
  {
    key: '/',
    title: '首页',
    icon: <AppOutline />,
  },
  {
    key: '/UserList',
    title: '用户',
    icon: <UserOutline />,
  },
  {
    key: '/Manage',
    title: '管理',
    icon: <UserOutline />,
  },
]

export default function Bottom() {
  const navigate = useNavigate();
  const { pathname } = useResolvedPath();
  if (pathname == "/Login") {
    return <div style={{ textAlign: "center", marginBottom: '10px' }}>
      <a href='https://beian.miit.gov.cn' target='_blank'>沪ICP备2024042844号-1</a>
    </div>
  }

  return (
    <TabBar activeKey={pathname} onChange={(path) => navigate(path)}>
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  )
}
