import React from 'react'
import Bottom from '@/components/Bottom'
import styles from '@/styles/layout.module.css'
import RouterRoot from '@/router'
import LoginCheck from './Login/LoginCheck'

export default function App(props) {
  return <>
    <LoginCheck/>
    <div className={styles.mainContent}>
      <RouterRoot />
    </div>
    <footer className={styles.bottom}><Bottom /></footer>
  </>
}
