import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { reduxStore } from './lib/redux'
import { Provider } from 'react-redux'
import '@/styles/globals.css'
import App from "./pages/App";

createRoot(document.getElementById("root")).render(
  <Provider store={reduxStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider >
);
