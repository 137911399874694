import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orders: [],
  date: 0,
}

export const deliveryInfoSlice = createSlice({
  name: 'deliveryInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setList: (state, action) => {
      state.orders = action.payload.orders;
      state.date = action.payload.date;
    },
  },
})
