import React, { lazy } from 'react';
import { Route, Routes } from 'react-router';

const DeliveryBuildingOverview = lazy(() => import('@/pages/DeliveryBuildingOverview'))
const Login = lazy(() => import("@/pages/Login"))
const UserList = lazy(() => import("@/pages/UserList"))
const Manage = lazy(() => import("@/pages/Manage"))
const InputUser = lazy(() => import("@/pages/Manage/InputUser"))
const PrintInputTags = lazy(() => import("@/pages/Manage/PrintInputTags"))
const SetProfile = lazy(() => import("@/pages/Manage/SetProfile"))
// const ChangePassword = lazy(() => import("@/pages/Manage/ChangePassword"))

let routeInfo = [
  { path: '/', element: <DeliveryBuildingOverview /> },
  { path: '/:date', element: <DeliveryBuildingOverview /> },
  { path: '/Login', element: <Login /> },
  { path: '/UserList', element: <UserList /> },
  { path: '/Manage', element: <Manage /> },
  { path: '/Manage/InputUser', element: <InputUser /> },
  { path: '/Manage/PrintInputTags', element: <PrintInputTags /> },
  { path: '/Manage/profile', element: <SetProfile /> },
  // { path: '/Manage/changepassword', element: <ChangePassword /> },
]

function getRouteObject(route) {
  if (!route || route.length == 0) return;
  return route.map(e => {
    return <Route path={e.path} key={e.path} element={<React.Suspense fallback={<p>Loading...</p>}>{e.element}</React.Suspense>}>
      {getRouteObject(route.children)}
    </Route>
  })
}

export default function RouterRoot() {
  return <Routes>{getRouteObject(routeInfo)}</Routes>
}
