import { registerUnloginHandler } from "@/lib/util";
import { useNavigate, useResolvedPath } from "react-router";


const LoginCheck = () => {
  let nav = useNavigate();
  const { pathname } = useResolvedPath();

  if (typeof window !== 'undefined' && window.localStorage) {
    let token = localStorage.getItem("token");
    if (token == null && pathname !== '/Login') {
      setTimeout(() => nav("/Login"), 0);
    }
  }

  registerUnloginHandler(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem("token");
      if (pathname !== '/Login') {
        setTimeout(() => nav("/Login"), 0);
      }
    }
  });
  return undefined;
}

export default LoginCheck
